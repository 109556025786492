<template>
  <div class="jfxq">
    <div class="main">
      <div class="list">
        <div class="top3" v-if="jfxq_store">
          <span class="text">{{ jfxq_store?.store_name }} : {{ jfxq_store?.wd_jifen }}</span>
        </div>
        <div class="item" v-for="item in jfxq_record">
          <div class="top">
            <div class="date">{{ item.input_time }}</div>
            <div class="number" :class="{ red: Number(item.zzjf) < 0, green: Number(item.zzjf) > 0 }">{{
              item.zzjf > 0 ?
                ('+' + item.zzjf) : item.zzjf
            }}</div>
          </div>
          <div class="core">
            <div class="inner">
              <van-tag color="#ffe1e1" text-color="#ad0000" size="medium">{{ item.description }}</van-tag>
              <span class="name" style="margin-left:12px;" >{{ item.df_sjhm || '--' }}</span>
            </div>
            <div class="amount">余额:{{ item.current_total_zzjf }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';

export default {
  name: 'jfxq',
  data() {
    return {
    }
  },
  created() {
    // console.log('hello');
    this.get_jfxq_record({
      sjhm: this.$route.query.sjhm
    });
  },
  mounted() {
    // console.log(this.user_detail);
    
    document.title = (this.user_detail?.ztmd?.store_name || '') + '积分详情';
  },
  computed: {
    ...mapState(['jfxq_record', 'user_detail', 'jfxq_store']),
  },
  methods: {
    ...mapActions(['get_jfxq_record']),
  },

}
</script>
<style lang="less" scoped>
.jfxq {

  .main {
    margin-top: 24px;

    .list {
      .top3 {
        margin:12px;
        padding:24px 12px;
        border-radius:5px;
        background:#fff;
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
          font-size:18px;
          color:#666;
        }
      }
      .item {
        margin: 12px;
        background: #fff;
        padding: 12px;
        border-radius: 5px;

        .top {
          display: flex;
          justify-content: space-between;

          .date {
            font-size: 14px;
            color: #666;
          }

          .number {
            font-size: 16px;

            &.red {
              color: red;
            }

            &.green {
              color: red;
            }
          }
        }

        .core {
          padding-top: 12px;
          display: flex;
          justify-content: space-between;

          .inner {
            display: flex;
            align-items: center;

            .name {
              color: #333;
              font-size: 16px;
              margin-right: 12px;
            }
          }

          .amount {
            font-size: 16px;
            color: #666;
          }
        }
      }
    }
  }
}
</style>